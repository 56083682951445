@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.cartTotalRow {
  padding: 10px 0px 10px 20px;
  max-width: 100%;
  width: 100vw;
  text-align: left;
  line-height: 1.6;
}
.cartTotalRow .dropdownMenu {
  padding: 0px 5px;
}
.cartTotalRow #dropdown-basic-button {
  width: 100vw;
  max-width: 100%;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 8px;
  border: 1px solid #95989A;
}
.cartTotalRow .dropdown-item {
  max-height: 450px;
  overflow-y: auto;
  font-size: 14px;
  margin-top: -10px;
}
.cartTotalRow .dropdown-toggle {
  display: flex;
  align-items: center;
}
.cartTotalRow .orderSummaryRow {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  text-transform: capitalize;
}
.cartTotalRow #cart-total-title {
  padding: 0;
}
.cartTotalRow .orderSummaryListContainer {
  background-color: #F4F4F4;
  white-space: normal;
  padding: 5px 15px;
}
.cartTotalRow .orderSummaryListContainer .orderSummaryListBrand {
  font-size: 12px;
}
.cartTotalRow .termLink {
  color: #E40189;
  font-weight: bold;
}
.cartTotalRow .boldFont {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.cartTotalRow .rightCol {
  text-align: right;
}
.cartTotalRow hr {
  margin: 5px 0px;
  border-top: 1px solid #95989A;
}
