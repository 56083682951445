@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #231F20;
}

.circle-progress {
  stroke: #F058A0;
  stroke-linecap: butt;
  stroke-linejoin: butt;
}

.circle-text {
  font-size: 25px;
  font-weight: bold;
  fill: #231F20;
}
