@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.subComparisonTable {
  text-align: center;
  font-weight: 700;
  line-height: normal;
}
.subComparisonTable thead, .subComparisonTable th, .subComparisonTable td {
  vertical-align: middle;
}
.subComparisonTable th {
  position: sticky;
  left: -5px;
}
.subComparisonTable img {
  display: block;
  margin-bottom: 5px;
  width: 100%;
}
.subComparisonTable svg {
  color: #4BAA64;
  font-size: 30px;
}
.subComparisonTable .featureColumn {
  background-color: #FFEBF3;
  min-width: 200px;
  z-index: 3;
  filter: drop-shadow(2px 0px 0px #D6D6D6);
}
.subComparisonTable .featureColumn p {
  font-weight: 400;
  margin-bottom: 0;
}
