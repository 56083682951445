@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.hero-container {
  min-height: 450px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .hero-container {
    background-size: cover;
    min-height: 370px;
  }
}
@media (max-width: 767px) {
  .hero-container {
    min-height: 180px;
  }
}
.hero-container-right {
  background-position: top left;
  background-size: contain;
}
@media (max-width: 767px) {
  .hero-container-right {
    min-height: 400px;
  }
}
.hero-container-center {
  background-size: cover;
}
.hero-container.hero-container-left {
  background-position: top right;
  background-size: contain;
}
@media (max-width: 767px) {
  .hero-container.hero-container-left {
    background-size: 115%;
  }
}
.hero-container-center-top {
  align-items: flex-start;
}
@media (max-width: 992px) {
  .hero-container-center-top {
    background-size: contain;
  }
}
.hero-container .hero-center {
  text-align: center;
}
@media (max-width: 767px) {
  .hero-container .hero-center {
    margin-top: 50%;
    margin-bottom: 5%;
  }
}
.hero-container .hero-center-top {
  text-align: center;
  margin-top: 60px;
}
@media (max-width: 992px) {
  .hero-container .hero-center-top {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 40%;
  }
}
.hero-container .hero-right {
  text-align: left;
  max-width: 550px;
  margin-left: 50%;
}
@media (max-width: 992px) {
  .hero-container .hero-right {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 50%;
    background-color: white;
    padding: 10px 10px;
  }
  .hero-container .hero-right h1 {
    padding: 0 15px;
  }
}
.hero-container .hero-left {
  text-align: left;
  max-width: 600px;
  margin-left: 50px;
}
@media (max-width: 1440px) {
  .hero-container .hero-left {
    max-width: 36%;
  }
}
@media (max-width: 992px) {
  .hero-container .hero-left {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 60%;
  }
  .hero-container .hero-left h1, .hero-container .hero-left p {
    padding: 10px;
  }
}
.hero-container p {
  font-size: 16px;
}
.hero-container .secondButton {
  width: 100%;
  margin: 15px 0px;
  color: #E40189;
  background-color: #FFFFFF;
  font-size: 1rem;
  text-decoration: underline;
  text-shadow: none;
  box-shadow: none;
}
.hero-container .secondButton:hover, .hero-container .secondButton:active, .hero-container .secondButton:not(:disabled):not(.disabled):active {
  color: #E40189;
  background-color: #FFFFFF;
  opacity: 0.8;
}
.hero-container .secondButton.btn-lg {
  font-size: 1.4em;
}
.hero-container #starsHappyMothers {
  font-size: 12px;
}
@media (max-width: 992px) {
  .hero-container #starsHappyMothers {
    text-align: center;
  }
}
