@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.upsellDetails {
  padding: 15px;
  margin: 0 -15px;
}
@media screen and (min-width: 992px) {
  .upsellDetails {
    display: flex;
    justify-content: flex-end;
  }
}
.upsellDetails .upsellText {
  text-align: left;
  padding-right: 0;
}
@media screen and (min-width: 992px) {
  .upsellDetails .upsellText {
    max-width: 30%;
  }
}
.upsellDetails .upsellImage {
  max-width: 150px;
  padding-right: 0;
}
.upsellDetails .price {
  color: #E40189;
  font-weight: bold;
  font-size: 20px;
}
.upsellDetails .priceSlashed {
  font-weight: normal;
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.upsellDetails .priceSlashed::before, .upsellDetails .priceSlashed::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.upsellDetails .priceSlashed::after {
  border-bottom: 2px solid #E40189;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
}

.upsellButton {
  width: 100%;
}
