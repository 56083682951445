@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.productItem {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  min-height: 40vh;
}
.productItem a {
  color: #000000;
  text-decoration: none;
}
.productItem a:hover {
  color: #95989A;
}
.productItem.horizontal {
  margin: 50px 0;
}
@media (max-width: 767px) {
  .productItem.horizontal {
    margin-bottom: 10px;
  }
}
.productItem .productImage {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .productItem .productImage {
    margin: 10px 0px;
  }
}
.productItem .productImage img {
  width: 100%;
  height: auto;
}
.productItem .productRecommendationsImage {
  width: 100%;
  height: auto;
  margin: 20px auto 0px auto;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.productItem .productRecommendationsImage img {
  position: relative;
  z-index: 1;
}
.productItem .shopProductInfo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  min-height: 20vh;
}
.productItem .shopProductInfo .priceWrapper {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.productItem .shopProductInfo .priceWrapper .productPrice {
  font-size: 16px;
  font-weight: 700;
  margin-left: 3px;
  margin-top: -5px;
}
.productItem .shopProductInfo .priceWrapper .productPriceHighlight {
  color: #231F20;
  font-weight: 600;
  font-size: 120%;
}
.productItem .shopProductInfo .productTitle {
  font-weight: bold;
  line-height: 1.2;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .productItem .shopProductInfo .productTitle {
    font-size: 12px;
  }
}
.productItem .shopProductInfo .productBrand {
  height: 26px;
  font-size: 12px;
  font-weight: 400;
}
.productItem .shopProductInfo .vip-discount {
  color: #F058A0;
  margin: 20px 0 0;
}
.productItem .shopProductInfo .viewDetailsButton {
  color: #E40189;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 0;
  margin-top: -15px;
  cursor: pointer;
}
.productItem .shopProductInfo .details {
  margin: 20px 0 0;
  text-decoration: underline;
  cursor: pointer;
}
.productItem .shopProductInfo .productDescription {
  line-height: 18px;
  margin-top: -10px;
}
@media screen and (max-width: 768px) {
  .productItem .shopProductInfo .productDescription {
    line-height: 12px;
    font-size: 10px;
  }
}
.productItem .shopProductInfo .productAddToCart {
  margin-top: 10px;
  font-weight: 700;
  width: 100%;
}
.productItem .shopProductInfo.horizontal {
  text-align: left;
}
@media (max-width: 767px) {
  .productItem .shopProductInfo.horizontal {
    text-align: center;
  }
}
.productItem .shopProductInfo.horizontal .priceWrapper {
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .productItem .shopProductInfo.horizontal .priceWrapper {
    justify-content: center;
  }
}
.productItem .productQuantityLeft {
  color: #E40189;
  text-align: center;
  font-weight: bold;
  font-size: smaller;
}
.productItem .disabled-link {
  cursor: default;
  pointer-events: none;
}
.productItem .addToCartShop {
  width: 100%;
  text-wrap: nowrap;
}

#postPurchaseProductItem {
  border: none !important;
}
@media (max-width: 992px) {
  #postPurchaseProductItem .slashedPrice {
    font-size: 12px;
  }
  #postPurchaseProductItem .productPrice {
    font-size: 14px;
  }
  #postPurchaseProductItem .productAddToCart {
    margin-top: 0;
  }
  #postPurchaseProductItem .productAddToCart .btn-primary {
    font-size: 10px;
  }
}
@media screen and (max-width: 350px) {
  #postPurchaseProductItem .productAddToCart .btn-primary {
    font-size: 7px;
  }
}
#postPurchaseProductItem .addToCartShop {
  white-space: nowrap !important;
  text-align: center !important;
}

.discountCallout {
  font-size: 100%;
  font-weight: 400;
  padding-right: 0.6em;
  color: #FF0000;
  text-decoration: none;
  margin-left: 5px;
}

.discountedPrice {
  text-decoration: line-through;
  color: #95989A;
  font-size: 90%;
}

.badge-primary {
  background-color: #E40189;
  font-size: 100%;
  margin-bottom: 0.5em;
}

.retailPriceText {
  font-weight: 500;
  font-size: 13px;
  color: #707070;
}

.monthlyPriceText {
  font-weight: 700;
  font-size: 13px;
  color: #231F20;
}

.monthlyPrice {
  font-weight: 700;
  font-size: 13px;
  color: #231F20;
}

.firstBoxPriceText {
  font-weight: 500;
  font-size: 13px;
  color: #707070;
}
