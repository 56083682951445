@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.termsAndConditions {
  color: #E40189;
  font-weight: bold;
  font-size: 1em;
  text-decoration: underline;
}
.termsAndConditions:hover {
  font-size: 1em;
  color: #95989A;
  font-weight: bold;
  cursor: pointer;
}

.isSubscribedToNewsletterCol, .isAgreedToTermsAndConditionsCol {
  padding-left: 15px;
}
