@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.subscription-signup-header-container {
  padding: 30px;
}
.subscription-signup-header-container .stepTitle {
  padding: 0;
}
.subscription-signup-header-container .stepTitle p {
  font-size: 24px;
  font-weight: 700;
  color: #E40189;
  margin: 0px -15px;
}
.subscription-signup-header-container .desktop-sub-signup-header {
  text-align: center;
  padding-top: 15px;
  margin-top: 15px;
}
.subscription-signup-header-container .desktop-sub-signup-header .circle {
  height: 10px;
  width: 10px;
  border: 2px solid #FC9CC3;
  border-radius: 15px;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
}
.subscription-signup-header-container .desktop-sub-signup-header .filled {
  background-color: #FC9CC3;
}
.subscription-signup-header-container .desktop-sub-signup-header .isHidden {
  display: none;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions {
  text-align: center;
  margin-top: 30px;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 34%, #e2e2e2 36%, #e2e2e2 63%, #ffffff 64%, #ffffff 100%);
  margin: 0px auto;
  border-radius: 10px;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle {
  background: #e2e2e2;
  color: #1b1b1b;
  cursor: pointer;
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 100;
  transition: all ease 0.4s;
  z-index: 2;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle h2, .subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle h3 {
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.17em;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle.monthly {
  border-radius: 10px 0px 0px 10px;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle.bundled {
  border-radius: 0px 10px 10px 0px;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle.active {
  background: #FC9CC3;
  transition: all ease 0.4s;
  z-index: 3;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle.active h2, .subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle.active h3 {
  font-weight: 700;
}
.subscription-signup-header-container .desktop-sub-signup-header-actions .toggle-wrapper .toggle:focus {
  box-shadow: 0px 0px 6px #FC9CC3;
  outline: dotted 1px #FC9CC3;
}
.subscription-signup-header-container .fullBackground {
  background: #FFEBF3;
}
